.polaris {
  :global(*) {
    --chat-desktop-max-height: 700px;
    --chat-desktop-max-width: 500px;

    --preview-height: 30vh;

    --fade-duration: 200ms;
    --horizonal-collapse-duration: 250ms;
    --horizonal-expand-duration: 300ms;
    --vertical-collapse-duration: 250ms;
    --vertical-expand-duration: 400ms;
  }

  :global(.chat-container) {
    @apply h-full
      max-h-0
      w-[calc(100vw-1rem)]
      overflow-hidden
      rounded-3xl
      rounded-br-none
      shadow-2xl
      md:max-w-[var(--chat-desktop-max-width)];

    transition: max-height var(--vertical-expand-duration)
      cubic-bezier(0.5, 0.25, 0, 1);

    transition-delay: 0s;
  }

  :global(.chat-chatbox) {
    @apply h-[75vh]
      overflow-y-auto
      md:max-h-[var(--chat-desktop-max-height)];

    :global(.toggle-chip) {
      @apply md:h-auto
        md:px-6
        md:py-4 #{!important};
    }
  }

  :global(.full-chat-container) {
    @apply relative
      w-full
      bg-white;
  }

  :global(.full-chat) {
    @apply absolute
      right-4
      top-3
      rounded-2xl
      bg-blue-70
      p-2
      text-gray-300
      hover:text-gray-400
      focus:text-gray-400
      focus:outline-none;
  }

  :global(.loading) {
    @apply flex h-full w-full items-center justify-center;
  }

  :global(.open-chat-container) {
    @apply fixed
      bottom-4
      right-4
      flex
      flex-col
      items-end
      justify-center
      md:right-4;

    z-index: 3147483600;
  }

  :global(.open-chat) {
    @apply flex
      w-full
      max-w-[52px]
      items-center
      rounded-full
      rounded-br-none
      bg-gradient-to-r
      from-blue-90
      to-blue-50
      p-0
      focus:shadow-none
      focus:outline-0;

    & > svg {
      @apply focus:shadow-none
        focus:outline-0;
    }

    transition:
      max-width var(--horizonal-expand-duration) cubic-bezier(0.5, 0.25, 0, 1),
      opacity var(--fade-duration) linear;

    transition-delay: calc(
        var(--vertical-collapse-duration) + var(--fade-duration)
      ),
      var(--vertical-collapse-duration);
  }

  :global(.overlay) {
    @apply fixed
      left-0
      top-0
      z-[700]
      h-screen
      w-screen
      bg-black
      bg-opacity-50;
  }

  :global(.preview-open-target) {
    @apply absolute
      left-0
      z-[750]
      md:h-[300px];

    display: none;
    height: var(--preview-height);
    width: 100%;
  }

  &:global(.open) {
    :global(.chat-container) {
      @apply max-h-[75vh];

      transition-delay: calc(
        var(--horizonal-expand-duration) + var(--fade-duration)
      );
    }

    :global(.open-chat) {
      @apply max-w-full
        opacity-0
        md:max-w-[var(--chat-desktop-max-width)];

      transition-delay: 0s, var(--horizonal-expand-duration);
    }
  }

  &:global(.preview) {
    :global(.loading) {
      @apply items-start pt-[64px];
    }

    :global(.chat-chatbox) {
      :global(.MuiGrid-item) {
        @apply pl-6
          pt-2;
      }
      :global(.toggle-chip) {
        @apply h-8
          px-3
          py-2;
      }
    }

    :global(.chat-container) {
      @apply md:max-h-[300px] #{!important};

      max-height: var(--preview-height);
      transition-delay: calc(
        var(--horizonal-expand-duration) + var(--fade-duration)
      );
    }

    :global(.multiselect-label) {
      @apply hidden;
    }

    :global(.open-chat) {
      @apply max-w-full
        opacity-0
        md:max-w-[var(--chat-desktop-max-width)];

      transition-delay: 0s, var(--horizonal-expand-duration);
    }

    :global(.full-chat-container:after) {
      @apply absolute
        h-[100px]
        w-full
        rounded-2xl
        bg-gradient-to-b
        from-transparent
        to-white
        md:top-[calc(300px-100px)] #{!important};

      content: '';
      top: calc(var(--preview-height) - 100px);
    }

    :global(.preview-open-target) {
      display: block;
    }
  }

  &:global(.fullscreen) {
    :global(.chat-chatbox) {
      @apply h-screen
        max-h-screen;
    }

    :global(.chat-container) {
      @apply max-h-screen
        w-full
        rounded-none;

      max-height: 100dvh;
    }

    :global(.open-chat-container) {
      @apply bottom-0
        px-0;
    }

    :global(.open-chat) {
      @apply opacity-0;
    }
  }
}
